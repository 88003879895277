<template>
  <v-container fluid tag="section">
    <v-row align-content="center">
      <v-col cols="12" sm="7" md="7" lg="7" align-self="end" class="pb-1">
        <v-btn-toggle
          active-class="active-filter"
          class="px-0 filter-dishe"
          borderless
          dense
          background-color="transparent"
          v-model="filter_status"
        >
          <v-btn
            text
            value="todo"
            class="primary--text"
            style="padding-left: 5px !important; padding-right: 5px !important"
          >
            Todos({{ dishes.length }})
          </v-btn>
          |
          <v-btn
            text
            value="1"
            class="primary--text"
            style="padding-left: 5px !important; padding-right: 5px !important"
          >
            Publicadas ({{ statusCount.public }})
          </v-btn>
          |
          <v-btn
            text
            value="0"
            class="primary--text"
            style="padding-left: 5px !important; padding-right: 5px !important"
          >
            Borrador ({{ statusCount.eraser }})
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="12" class="pb-1" md="5" lg="5" sm="5">
        <v-text-field
          hide-details
          prepend-inner-icon="mdi-magnify"
          single-line
          outlined
          rounded
          dense
          color="primary"
          placeholder="Buscar platos"
          v-model="search"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card flat class="mt-0">
          <!--           <v-card-title class="pl-0">
            <v-row>
              <v-col col="6">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn rounded small outlined color="sidebar" v-on="on" style="padding-left: 10px !important; padding-right: 10px !important">
                      Acciones en lote
                      <v-icon small right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                      <v-list-item>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Eliminar</v-list-item-title>
                      </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn rounded small depressed color="sidebar" class="ml-2" style="padding-left: 10px !important; padding-right: 10px !important">
                  Aplicar
                </v-btn>
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn rounded small outlined color="sidebar" v-on="on" style="padding-left: 10px !important; padding-right: 10px !important">
                      <v-icon small>mdi-filter</v-icon>
                      Filtrar
                      <v-icon small right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                      <v-list-item>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>Eliminar</v-list-item-title>
                      </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-title> -->
          <v-sheet class="" v-if="load_dish">
            <v-responsive max-width="900" class="mx-auto">
              <v-skeleton-loader
                ref="skeleton"
                type="table"
                class="mx-auto"
              ></v-skeleton-loader>
            </v-responsive>
          </v-sheet>
          <!--<v-data-table
            v-else
            :headers="headers"
            :items="dishes_filter"
            class="elevation-0 striped"
            :search="search"
            :sort-by.sync="sort_dishe"
            no-data-text="No se encontraron Usuarios"
            no-results-text="No se encontraron Coincidencias"
            show-select
            fixed-header
            item-key="id"
            loading-text="Obteniendo Usuarios"
            v-model="selected_dishe"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-row v-if="$vuetify.breakpoint.smAndUp">
                <v-col cols="6" class="d-flex align-center">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn rounded small outlined color="sidebar" v-on="on" style="padding-left: 10px !important; padding-right: 10px !important">
                        Acciones en lote
                        <v-icon small right>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                          <v-list-item-title>Editar</v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>Eliminar</v-list-item-title>
                        </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn rounded small depressed color="sidebar" class="ml-2" style="padding-left: 10px !important; padding-right: 10px !important">
                    Aplicar
                  </v-btn>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn rounded small outlined color="sidebar" v-on="on" style="padding-left: 10px !important; padding-right: 10px !important">
                        <v-icon small>mdi-filter</v-icon>
                        Ordenar
                        <v-icon small right>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(filter,i) in filters_head" :key="i"  @click="sort_dishe = filter.value">
                          <v-list-item-title v-text="filter.text"></v-list-item-title>
                        </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col cols="6" class="px-0">
                  <v-data-footer
                    style="border:none !important"
                    :pagination="pagination"
                    :options="options"
                    @update:options="updateOptions"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
                </v-col>
              </v-row>
            </template>
             <template v-slot:item.data-table-select="{ isSelected, select }">
              <v-simple-checkbox color="info" :value="isSelected" @input="select($event)"></v-simple-checkbox>
            </template>
             <template v-slot:header.imageurl>
              <v-icon color="blue-grey lighten-3" dark>mdi-image</v-icon>
            </template>
            <template v-slot:item.imageurl="{item}">
              <v-avatar size="32">
                <img :src="storage+item.imageurl" />
              </v-avatar>
               <v-icon color="blue-grey lighten-3" dark>mdi-image-outline</v-icon>
            </template>
            <template v-slot:header.favorite>
              <v-icon color="blue-grey lighten-3" dark>mdi-star</v-icon>
            </template>
            <template v-slot:item.favorite>
              <v-icon color="blue-grey lighten-3" dark>mdi-star-outline</v-icon>
            </template>
            <template v-slot:item.name="{ item }">
              <span class="font-weight-bold d-block">{{ item.name.es }}</span>
               <v-chip v-if="item.visibility == 0" class="my-2" color="info" small>Borrador</v-chip>
            </template>
             <template v-slot:item.publication_date="{ item }">
              <span class="font-italic blue-grey--text">{{ item.publication_date | smartDate}}</span>
            </template>
            <template v-slot:item.allergens="{ item }">
              {{ item.allergens | allergens}}
            </template>
            <template v-slot:item.categories="{ item }">
              {{ item.categories | categories}}
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="text-right">
                <v-btn icon color="primary lighten-1" @click="$router.push(url_edit+item.id)" v-if="$can('Editar plato')">
                  <v-icon color="primary lighten-1">mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon color="primary lighten-1"  @click="deleteDishe(item)" v-if="$can('Borrar plato')">
                  <v-icon color="primary lighten-1">mdi-trash-can-outline</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>-->
          <v-row>
            <v-col v-for="(item, i) in dishes" :key="i" cols="12" md="3">
              <v-card>
                <v-card-title class="text-center">{{
                  item.name.es
                }}</v-card-title>
                <v-card-text class="text-center">
                  <div class="w-100">
                    <img
                      :src="storage + item.imageurl"
                      style="margin: 0 auto;display:block;height: 150px; width:100%"
                    />
                  </div>
                  <div class="w-100">
                    <span class="font-italic blue-grey--text">{{
                      item.publication_date | smartDate
                    }}</span>
                  </div>
                   <v-divider class="mx-4 my-4"></v-divider>
                  <h3>Categoría:</h3>
                  {{ item.categories | categories }}
                  <h3>Alérgenos:</h3>
                  {{ item.allergens | allergens }}
                </v-card-text>
                <v-expansion-panels class="mt-3">
                  <v-expansion-panel>
                    <v-expansion-panel-header class="text-center">
                        <span><v-icon color="blue-grey">mdi-cash</v-icon> Ver Variaciones</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="row" >
                        <div
                          class="col-6 text-left"
                          v-for="(p, i) in item.price"
                          :key="i"
                        >
                          <h5>{{ p.name }}:</h5>
                          <span v-for="(detail, idx) in filterPrice(p.prices)" :key="idx"
                            >
                            {{ formatNumber(detail.amount,coinCountry[detail.coin]) }}
                          </span>
                        </div>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-bottom-navigation activeClass="v-btn--hover">
                  <v-btn
                    color="deep-green lighten-2"
                    text
                    large
                    @click="$router.push(url_edit + item.id)"
                    v-if="$can('Editar menu')"
                  >
                    <v-icon color="secundary">mdi-pencil</v-icon> Editar
                  </v-btn>
                  <v-btn
                    large
                    color="deep-red lighten-2"
                    text
                    @click="deleteDishe(item)"
                    v-if="$can('Borrar menu')"
                  >
                    <v-icon color="secundary">mdi-trash-can-outline</v-icon>
                    Eliminar
                  </v-btn>
                </v-bottom-navigation>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import coinSymbol from "../../../mixins/coinSymbol"
import coinCountry from "../../../mixins/coinCountry"
export default {
  name: "dishes",
  data() {
    return {
      snackbar: {
        text: "",
        active: false,
        type: ""
      },
      filter_status: "todo",

      search: "",
      selected_dishe: [],
      load_dish: false,
      saving_user: false,
      show: false,
      dishes: [],
      roles: [],
      restaurants: [],
      sort_dishe: "",
      headers: [
        {
          text: "",
          value: "imageurl",
          sortable: false,
          width: "60",
          align: "center"
        },
        { text: "Nombre", value: "name", class: "primary--text" },
        // { text: 'SKU', value: 'sku', class:"primary--text" },
        { text: "Precio", value: "price", class: "primary--text" },
        { text: "Categoría", value: "categories", class: "primary--text" },
        { text: "Alérgenos", value: "allergens", class: "primary--text" },
        // { text: '', value: 'favorite' },
        { text: "Fecha", value: "publication_date", class: "primary--text" },
        { text: "", value: "actions", class: "primary--text", width: "150" }
      ],
      restaurant_id: "",
      id_user: "",
      action_user: "Crear",
      modal_user: false,
      url_edit: "",
      storage: "",
      value:"",
      coins:[]
    };
  },
  created() {
    if (this.$store.getters["auth/isAdmin"]) {
      this.restaurant_id = this.$route.params ? this.$route.params.id : "";
      this.url_edit = `/tc-admin/restaurante/${this.restaurant_id}/administrar/plato/`;
    } else {
      this.restaurant_id = this.$store.getters["auth/restaurant"];
      this.url_edit = `/tc-admin/platos/`;
    }
    this.storage = process.env.VUE_APP_IMG_URL_API + "images/dishes/";
    this.initCoins();
    this.getDishes();

    this.$store.commit("SET_OVERLAY", false);


  },
  methods: {
    initCoins(){
      const url = `api/restaurants/settings?restaurant_id=${this.restaurant_id}`;
      this.$api.get(url).then(res=>{
        this.coins = JSON.parse(res.data.data.settings.coins);
      });
    },
    async getDishes() {
      this.load_dish = true;
      this.dishes = [];
      try {
        const { data } = await this.$api.get(
          `api/dishes?restaurant_id=${this.restaurant_id}`
        );

        this.dishes = data.data.dishes;

        this.load_dish = false;
      } catch (error) {
        this.load_dish = false;
        this.snackbar = {
          text: "Lo sentimos, hubo un error al intentar obtener los usuarios",
          type: "error",
          active: true
        };
      }
    },
    async deleteDishe(item) {
      const msj =
        "Se eliminaran permanentemente los datos del plato " + item.name.es;

      const confirm = await this.$root.$confirm(
        "¿Estas Seguro de Eliminar?",
        msj,
        {
          type: "delete",
          btnConfirmText: "Si, Eliminalo",
          btnCancelText: "Descartar"
        }
      );

      if (confirm) {
        this.load_dish = true;
        const url = "api/dishes/" + item.id;
        try {
          const { data } = await this.$api.delete(url);
          this.load_dish = false;
          this.getDishes();
          this.search = "";
          this.$root.$snakbar({
            text: data.message,
            type: "success",
            active: true
          });
        } catch (error) {
          this.load_dish = false;
          if (error.response.status == "400") {
            this.$root.$snakbar({
              text: error.response.data.message,
              type: "error",
              active: true
            });
          } else {
            this.$root.$snakbar({
              text: "Lo sentimos, hubo un error al intentar eliminar el Plato.",
              type: "error",
              active: true
            });
          }
        }
      }
    },
    formatNumber(val, country){
      this.$numeral.locale(country)
      return this.$numeral(val).format('0,0[.]00 $')
    },
    filterPrice(price){
      var prices = Object.values(price)
      var exists = false;
      for (let a = 0; a < prices.length; a++) {
        const price = prices[a];
        for (let e = 0; e < this.coins.length; e++) {
          const coins = this.coins[e];
          exists = false;
          if (price.coin == coins.cod) {
            exists =  true;
            break;
          }
        }
        if (!exists) {
          prices.splice(a,1);
        }
      }
      return prices;
    }
  },
  computed: {
    filters_head() {
      return this.headers.filter(item => item.text != "");
    },
    statusCount() {
      return {
        public: this.dishes.filter(item => item.visibility == 1).length,
        eraser: this.dishes.filter(item => item.visibility == 0).length
      };
    },
    dishes_filter() {
      return this.dishes.filter(item => {
        if (this.filter_status == "todo") return true;

        return item.visibility == this.filter_status;
      });
    },
    coinSymbol() {
      return coinSymbol
    },
    coinCountry() {
      return coinCountry
    }
  },
  filters: {
    allergens(item) {
      return item.map(item => item.name).join(", ");
    },
    categories(item) {
      return item
        .map(item => {
          const name = JSON.parse(item.name);
          return name.es;
        })
        .join(", ");
    }
  }
};
</script>
<style scope>
.active-filter {
  font-weight: bold;
  color: #283145 !important;
  background: white !important;
}
.active-filter:before {
  background-color: transparent !important;
}
</style>
